<template>
  <v-layout
    wrap
    justify-center
    column
    class="social-team-users"
    ref="videoGrid"
  >
    <PillCountdown
      :endTime="endTime"
      ref="countdown"
      class="social-team-users__countdown-timer"
    />
    <draggable
      class="video-container-wrap wrap aligh-center justify-center"
      style="overflow-y: auto"
      tag="v-layout"
      v-model="sortedPlays"
      v-if="MissionType.VideoTeam !== mission.behavior"
    >
      <div
        class="video-card"
        v-for="(media, index) in sortedPlays"
        :key="`${index}-${media.id}-${media.userID}-${media.time}`"
      >
        <div class="video-card__body">
          <div class="video-card__media">
            <div>
              <VideoComponent
                :src="showVideo(media.correct)"
                autoplay
                muted
                loop
                ref="videoMedia"
              />
            </div>
          </div>
        </div>
      </div>
    </draggable>
    <draggable
      class="video-container-wrap wrap aligh-center justify-center d-flex"
      style="overflow-y: auto"
      tag="div"
      force-fallback
      v-model="teamVideos"
      v-bind="{
        group: 'people',
        animation: 150
      }"
      v-else
    >
      <div
        class="video-card"
        v-for="(media, index) in teamVideosArrayClone"
        :key="`${index}-${media.id}-${media.userID}-${media.time}`"
      >
        <div class="video-card__body">
          <div class="video-card__media">
            <VideoComponent
              :src="media.video"
              autoplay
              muted
              loop
              ref="videoMedia"
            />
          </div>
        </div>
      </div>
    </draggable>
    <v-flex shrink class="mb-4">
      <rtb-button
        v-if="isScribe"
        class="mr-2"
        @click="submitPlays"
        :disabled="
          processingSubmit || (hasSubmittedTeamGif && !hasUnsummttedGif)
        "
      >
        {{ hasSubmittedText }}
      </rtb-button>
      <rtb-button
        v-if="
          hasSubmitedVideo &&
          !hasSubmittedTeamGif &&
          MissionType.VideoTeam !== mission.behavior
        "
        @click="retakeVideo"
      >
        Redo
      </rtb-button>
      <div class="social-team-users__control" v-if="missionAudioSrc">
        <SyncedMedia
          :src="missionAudioSrc"
          :autoplay="false"
          :loop="true"
          v-model="globalState"
        />
        <SvgIcon
          name="pause-circle"
          width="50"
          height="50"
          class="social-team-users__control__svg"
          @click="globalState = { paused: true }"
          v-if="!globalState.paused"
        />
        <SvgIcon
          v-else
          name="play-circle"
          width="60"
          height="60"
          class="social-team-users__control__svg"
          @click="globalState = { paused: false }"
        />
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import draggable from "vuedraggable"
import { isEqual, differenceWith } from "lodash"

import { db } from "@/firebase"

import { RtbButton } from "@/components/ui"
import MissionType from "@shared/enums/Mission"
import SyncedMedia from "@/modules/smart-media/components/SyncedMedia.vue"
import PillCountdown from "@/components/GroupTeams/Common/PillCountdown.vue"
import VideoComponent from "@/components/ui/VideoComponent/VideoComponent.vue"

import { GameMixin } from "@/mixins"

export default {
  name: "SocialVideo",
  mixins: [GameMixin],
  components: {
    VideoComponent,
    draggable,
    RtbButton,
    SyncedMedia,
    PillCountdown
  },
  data() {
    return {
      missionPlaysArrayClone: [],
      teamVideosArrayClone: [],
      containerWidth: 0,
      containerHeight: 0,
      processingSubmit: false,
      MissionType
    }
  },
  mounted() {
    this.$nextTick(() => this.getGridSizes())
    window.addEventListener("resize", this.getGridSizes)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getGridSizes)
  },
  watch: {
    missionPlaysArray: {
      handler() {
        this.missionPlaysArrayClone = this.missionPlaysArray
      },
      immediate: true,
      deep: true
    },
    teamSubmittedVideoArray: {
      handler(val) {
        this.teamVideosArrayClone = val
      },
      immediate: true,
      deep: true
    },
    rowNumber(value) {
      document.documentElement.style.setProperty("--video-rows-count", value)
    }
  },
  computed: {
    ...mapGetters([
      "getCurrentMission",
      "getCurrentMode",
      "missionPlaysArray",
      "gameID",
      "orgID",
      "user",
      "onlineUsersArray",
      "isScribe",
      "gameStatus"
    ]),
    ...mapGetters("auth", ["isHost"]),
    ...mapGetters({
      mission: "getCurrentMission",
      teams: "chats",
      game: "actualGame"
    }),
    team() {
      const teamID = this.isHost
        ? this.$store.getters["group/globalTeamID"]
        : this.user?.teamID
      return this.teams?.[teamID]
    },
    currentMissionID() {
      return this.mission?.id
    },
    hasSubmittedTeamGif() {
      return Boolean(Array.isArray(this.teamPlays) && this.teamPlays.length)
    },
    teamPlays() {
      return this.missionPlaysArray.filter(
        mission => mission.teamID === this.team?.id
      )
    },
    endTime() {
      const int = parseInt(this.gameStatus.endTime) || 0
      const endTime = int - Date.now()
      return endTime > 0 ? endTime : 0
    },
    hasSubmittedText() {
      if (this.hasSubmittedTeamGif && this.hasUnsummttedGif) return "Re-Submit"
      else if (this.hasSubmittedTeamGif) return "Submitted"
      return "Submit"
    },
    unsubmittedPlays() {
      const plays = this.teamPlays
        .filter(play => play?.answer?.[0].video)
        .map(play => ({
          userID: play.userID,
          video: play?.answer?.[0].video
        }))

      const teamVideos = Object.values(this.team?.teamVideos || {}).map(
        video => ({ userID: video.userID, video: video.video })
      )

      return differenceWith(teamVideos, plays, isEqual)
    },
    hasUnsummttedGif() {
      return (
        this.hasSubmittedTeamGif &&
        Array.isArray(this.unsubmittedPlays) &&
        this.unsubmittedPlays.length
      )
    },
    rowNumber() {
      if (!this.containerHeight) {
        return 5
      }

      const countItems = this.missionPlaysArrayClone.length
      let numberOfRows = 4
      _.range(1, 4, 0.01).reduce((prevGridSize, rows) => {
        const itemWidth = this.containerHeight / rows
        const itemsInRow = Math.floor(this.containerWidth / itemWidth)
        const nextGridSize = itemsInRow * Math.floor(rows)
        if (countItems >= prevGridSize + 1 && countItems <= nextGridSize) {
          numberOfRows = rows
        }
        return nextGridSize
      }, 0)
      return numberOfRows
    },
    sortedPlays: {
      get() {
        return this.missionPlaysArrayClone.sort((a, b) => a?.pos - b?.pos)
      },
      async set(val) {
        this.missionPlaysArrayClone = val

        const { gameID, orgID } = this
        const updates = val
          .filter(play => play?.id)
          .reduce((acc, cur, index) => {
            acc[`${cur.id}/pos`] = index
            return acc
          }, {})

        await db
          .auxiliary()
          .ref(`org/${orgID}/game/${gameID}/play`)
          .update(updates)
      }
    },
    teamSubmittedVideoArray() {
      return Object.values(this.team?.teamVideos || {})
    },
    teamVideos: {
      get() {
        return this.teamVideosArrayClone.sort((a, b) => a?.pos - b?.pos)
      },
      async set(val) {
        this.teamVideosArrayClone = val
        const { gameID, orgID } = this
        const updates = val.reduce((acc, cur, index) => {
          acc[`${cur.userID}/pos`] = index
          return acc
        }, {})
        await db
          .auxiliary()
          .ref(`org/${orgID}/game/${gameID}/teams/${this.team?.id}/teamVideos`)
          .update(updates)
      }
    },
    hasSubmitedVideo() {
      return this.team?.teamVideos?.[this.user.id]
    },
    missionAudioSrc() {
      return this.mission?.audio
    },
    globalState: {
      get() {
        const { audioPaused, audioStartedAt, audioCurrentTime } =
          this.game?.teamVideoAudio?.[this.user.teamID] || {}
        return {
          paused: (audioPaused ?? true) || !this.missionAudioSrc,
          currentTime: audioCurrentTime,
          startedAt: audioStartedAt
        }
      },
      set(value) {
        this.updateGameAudio({
          audioPaused: value.paused,
          audioCurrentTime: value.currentTime || null,
          audioStartedAt: value.startedAt || null
        })
      }
    }
  },
  methods: {
    showVideo(media) {
      if (Array.isArray(media)) {
        return media?.[0]?.video
      } else {
        return media?.video
      }
    },
    showImage(media) {
      if (Array.isArray(media)) {
        return media[0].image
      } else {
        return media.image
      }
    },
    getGridSizes() {
      this.containerWidth = this.$refs?.videoGrid?.clientWidth
      this.containerHeight = this.$refs?.videoGrid?.clientHeight
      document.documentElement.style.setProperty(
        "--meeting-grid-hight",
        `${this.containerHeight}px`
      )
    },
    async submitPlays() {
      try {
        await this.$confirm({
          message: "This is submitting for your whole team."
        })

        this.processingSubmit = true

        if (this.hasUnsummttedGif) {
          const update = {}
          const unsubmittedPlaysMappedByUserID = this.unsubmittedPlays.reduce(
            (acc, { userID, video }) => {
              acc[userID] = { userID, video }
              return acc
            },
            {}
          )
          this.missionPlaysArray
            .filter(
              play =>
                play.teamID === this.user.teamID &&
                unsubmittedPlaysMappedByUserID[play.userID]
            )
            .forEach(play => {
              update[
                `org/${this.orgID}/game/${this.gameID}/play/${play.id}/correct/video`
              ] = unsubmittedPlaysMappedByUserID[play.userID]?.video ?? null
              update[
                `org/${this.orgID}/game/${this.gameID}/play/${play.id}/answer/0/video`
              ] = unsubmittedPlaysMappedByUserID[play.userID]?.video ?? null
            })

          await db.auxiliary().ref().update(update)
        } else {
          for (let gifPayload of this.teamVideos) {
            const userID = gifPayload?.userID

            if (userID) {
              const user = this.getUserPayload(userID)
              this.videoUrl = gifPayload?.video
              if (user)
                await this.checkAnswer({
                  sentMissionID: this.currentMissionID,
                  user: { ...user, pos: gifPayload.pos ?? null }
                })
            }
          }
        }
      } catch (e) {}

      this.processingSubmit = false
    },
    getUserPayload(userID) {
      return this.onlineUsersArray.find(({ id }) => id === userID)
    },
    async retakeVideo() {
      await db
        .auxiliary()
        .ref(
          `org/${this.orgID}/game/${this.gameID}/teams/${this.user.teamID}/teamVideos/${this.user.id}/correct`
        )
        .set(null)
    },
    async updateGameAudio(value = {}) {
      await db
        .auxiliary()
        .ref(
          `org/${this.orgID}/games/${this.gameID}/teamVideoAudio/${this.team?.id}`
        )
        .update(value)
    }
  }
}
</script>

<style lang="scss">
.social-team-users {
  position: relative;
  height: 100%;
  width: 100%;

  &__countdown-timer {
    align-self: center;
  }

  .video-container-wrap {
    flex: 1;
    align-items: center;
    align-content: center;
    .video-card {
      min-width: 150px;
      max-height: 40vh;
      max-width: 40vh;
      cursor: pointer;
      will-change: flex-basis;
      transition: flex-basis 0.3s;
      flex: 0 0
        calc(
          (var(--meeting-grid-hight) - 10px) / var(--video-rows-count, 0) *
            var(--scaling-card, 1)
        );

      &__media {
        position: absolute !important;
        top: -0.5%;
        bottom: -0.5%;
        left: -0.5%;
        right: -0.5%;
        > div {
          background-size: cover;
          min-height: 100%;
          min-width: 100%;
        }
      }

      &__body {
        transform: translateZ(0);
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;
        border-radius: 3px;

        &::before {
          content: "";
          display: block;
          padding-top: 100%;
          width: 100%;
        }

        video,
        img {
          position: relative;
          display: block;
          flex-shrink: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          right: 0;
          bottom: 0;
          background-size: cover;
          overflow: hidden;
          &:not(.flipped) {
            background: transparent;

            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            transition: all 0.3s ease-in;

            &::-webkit-media-controls-panel {
              /*Transform pseudo element with controls */
              -moz-transform: scale(-1, 1);
              -o-transform: scale(-1, 1);
              -webkit-transform: scale(-1, 1);
              transform: scale(-1, 1);
            }
            &::-webkit-media-controls {
              background: transparent;
            }
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }

  &__control {
    top: -10px;
    left: 10px;
    position: absolute;

    &__svg {
      cursor: pointer;
    }
  }
}
</style>
