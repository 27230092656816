var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      ref: "videoGrid",
      staticClass: "social-team-users",
      attrs: { wrap: "", "justify-center": "", column: "" },
    },
    [
      _c("PillCountdown", {
        ref: "countdown",
        staticClass: "social-team-users__countdown-timer",
        attrs: { endTime: _vm.endTime },
      }),
      _vm.MissionType.VideoTeam !== _vm.mission.behavior
        ? _c(
            "draggable",
            {
              staticClass:
                "video-container-wrap wrap aligh-center justify-center",
              staticStyle: { "overflow-y": "auto" },
              attrs: { tag: "v-layout" },
              model: {
                value: _vm.sortedPlays,
                callback: function ($$v) {
                  _vm.sortedPlays = $$v
                },
                expression: "sortedPlays",
              },
            },
            _vm._l(_vm.sortedPlays, function (media, index) {
              return _c(
                "div",
                {
                  key:
                    index +
                    "-" +
                    media.id +
                    "-" +
                    media.userID +
                    "-" +
                    media.time,
                  staticClass: "video-card",
                },
                [
                  _c("div", { staticClass: "video-card__body" }, [
                    _c("div", { staticClass: "video-card__media" }, [
                      _c(
                        "div",
                        [
                          _c("VideoComponent", {
                            ref: "videoMedia",
                            refInFor: true,
                            attrs: {
                              src: _vm.showVideo(media.correct),
                              autoplay: "",
                              muted: "",
                              loop: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              )
            }),
            0
          )
        : _c(
            "draggable",
            _vm._b(
              {
                staticClass:
                  "video-container-wrap wrap aligh-center justify-center d-flex",
                staticStyle: { "overflow-y": "auto" },
                attrs: { tag: "div", "force-fallback": "" },
                model: {
                  value: _vm.teamVideos,
                  callback: function ($$v) {
                    _vm.teamVideos = $$v
                  },
                  expression: "teamVideos",
                },
              },
              "draggable",
              {
                group: "people",
                animation: 150,
              },
              false
            ),
            _vm._l(_vm.teamVideosArrayClone, function (media, index) {
              return _c(
                "div",
                {
                  key:
                    index +
                    "-" +
                    media.id +
                    "-" +
                    media.userID +
                    "-" +
                    media.time,
                  staticClass: "video-card",
                },
                [
                  _c("div", { staticClass: "video-card__body" }, [
                    _c(
                      "div",
                      { staticClass: "video-card__media" },
                      [
                        _c("VideoComponent", {
                          ref: "videoMedia",
                          refInFor: true,
                          attrs: {
                            src: media.video,
                            autoplay: "",
                            muted: "",
                            loop: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
      _c(
        "v-flex",
        { staticClass: "mb-4", attrs: { shrink: "" } },
        [
          _vm.isScribe
            ? _c(
                "rtb-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    disabled:
                      _vm.processingSubmit ||
                      (_vm.hasSubmittedTeamGif && !_vm.hasUnsummttedGif),
                  },
                  on: { click: _vm.submitPlays },
                },
                [_vm._v(" " + _vm._s(_vm.hasSubmittedText) + " ")]
              )
            : _vm._e(),
          _vm.hasSubmitedVideo &&
          !_vm.hasSubmittedTeamGif &&
          _vm.MissionType.VideoTeam !== _vm.mission.behavior
            ? _c("rtb-button", { on: { click: _vm.retakeVideo } }, [
                _vm._v(" Redo "),
              ])
            : _vm._e(),
          _vm.missionAudioSrc
            ? _c(
                "div",
                { staticClass: "social-team-users__control" },
                [
                  _c("SyncedMedia", {
                    attrs: {
                      src: _vm.missionAudioSrc,
                      autoplay: false,
                      loop: true,
                    },
                    model: {
                      value: _vm.globalState,
                      callback: function ($$v) {
                        _vm.globalState = $$v
                      },
                      expression: "globalState",
                    },
                  }),
                  !_vm.globalState.paused
                    ? _c("SvgIcon", {
                        staticClass: "social-team-users__control__svg",
                        attrs: {
                          name: "pause-circle",
                          width: "50",
                          height: "50",
                        },
                        on: {
                          click: function ($event) {
                            _vm.globalState = { paused: true }
                          },
                        },
                      })
                    : _c("SvgIcon", {
                        staticClass: "social-team-users__control__svg",
                        attrs: {
                          name: "play-circle",
                          width: "60",
                          height: "60",
                        },
                        on: {
                          click: function ($event) {
                            _vm.globalState = { paused: false }
                          },
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }